import React from "react";
import { IRTEModule } from "../BasePageDesigner";

type BaseProps = {
  moduleRTE: IRTEModule[];
  placeholderText: string;
  updatedManifest: any;
  textBlockIndex: number;
  assignNewText: (newText: string) => void;
  displayImage: (imageIndex: number) => JSX.Element;
  handleImageDivClick: (index: number) => void;
  // selectImages: () => Promise<void>,
};

const BaseSingleGraphicCenter: React.FC<BaseProps> = (props: any) => {
  return (
    <div className="graphic-center-container">
      <div className="double-graphic-center-image-container" onClick={() => props.handleImageDivClick(0)} id="0">
        <div className="img-aspect-container-wrapper">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">{props.displayImage(0)}</div>
          </div>
        </div>
      </div>
      <div className="double-graphic-center-image-container" onClick={() => props.handleImageDivClick(1)} id="0">
        <div className="img-aspect-container-wrapper">
          <div className="img-aspect-container-inside">
            <div className="img-aspect-centering">{props.displayImage(1)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseSingleGraphicCenter;
